:root
  --primary-color: #501919
  --color-primary: #501919
  --primary-r: 80
  --primary-g: 25
  --primary-b: 25
  --primary-h: 0
  --primary-s: 52.4%
  --primary-l: 20.6%
  --switch: 100%
:root
  --secondary-color: #FFFFFF
  --color-secondary: #FFFFFF
